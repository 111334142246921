.login-wrapper {
  max-width: 568px;
  margin: 0 auto;
  @media @screen767 {
    min-width: 100%;
    height: calc(100vh - 169px);
  }
  @media @screen350 {
    min-width: 100%;
    height: calc(100vh - 200px);
  }
  @media @screen320 {
    min-width: 100%;
    height: calc(100vh - 240px);
  }
  .login-alert {
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .form-wrapper {
    padding: 32px;
    border-radius: 8px;
    background: var(--color-neturals10);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
    .text-verify {
      font-size: 32px;
      @media @screen767 {
        font-size: 28px;
      }
    }
    @media @screen767 {
      padding: 24px 0 16px 0;
      border-radius: 16px;
      box-shadow: none;
      height: 100%;
      display: flex;
      flex-direction: column;
      .form-wrapper-headline {
        .headingTwo();
        font-size: 28px;
        line-height: 34px;
      }
    }

    .login-form {
      .ant-form-item {
        .ant-form-item-label {
          padding: 0;
          label {
            height: 24px;
            margin-bottom: 12px;
            &::before {
              all: unset;
            }
            &::after {
              display: inline-block;
              margin-inline-end: 4px;
              color: #ff4d4f;
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: '*';
              visibility: visible;
            }
            .bodyMeta();
            @media @screen767 {
              margin-bottom: 4px;
            }
          }
        }
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input {
              background-color: var(--color-neturals10);
              &.ant-input-disabled {
                background-color: var(--color-neturals8);
              }
              &.ant-input-lg {
                .bodyS(600);
                color: var(--color-neturals0);
              }
            }
            .ant-otp {
              width: 100%;
              column-gap: 16px;
              .ant-input {
                height: 48px;
                font-weight: 600;
                @media @screen767 {
                  height: 40px;
                }
              }
            }
          }
        }
      }
      .otp-countdown {
        .text-resend {
          .bodyBase();
          color: var(--color-neturals4);

          .resend-otp-btn:hover {
            color: var(--color-neturals3);
          }
        }
      }
      .form-fields-wrapper {
        .otp-form-field {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              div {
                gap: 16px;
              }
              input {
                flex: 1;
              }
              .ant-input {
                height: 48px;
                font-weight: 600;
                @media @screen767 {
                  height: 40px;
                }
              }
            }
          }
        }
        @media @screen767 {
          flex: 1;
        }
      }
      @media @screen767 {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
